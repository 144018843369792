@import 'lib/shared-assets/styles/_variables.scss';
.form-logo-container {
  position: relative;
  width: 100%;
  height: 100%;

  .btn {
    min-width: unset;
  }
}

.form-logo {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.img-thumbnail.form-changed {
  border-color: $byggtjanst-primary;
}

.placeholder-logo {
  margin: 0.5rem;
  width: 98px;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  border: 1px solid $byggtjanst-gray-four;
  border-radius: 74.5px;
  padding-bottom: 7px;
}

.btn-logo {
  &.button-edit {
    padding: 0;
    border: 0;
    box-shadow: none;
    color: $byggtjanst-gray-three;
  }
  &:hover {
    color: $byggtjanst-gray;
  }
}

.logo-edit-buttons {
  margin-top: -1.25rem;
}

.logo-edit-inline {
  .logo-edit-buttons {
    position: absolute;
    margin-top: 0;
    top: 0.75rem;
    right: 0.5rem;
    z-index: 3;
    svg {
      color: white;
      filter: drop-shadow(1px 1px 0.7px black);
      &:hover {
        color: $byggtjanst-gray-light;
      }
    }
  }
}
