@import '../../../lib/shared-assets/styles/_variables.scss';

.top-bar {
  width: calc(100% - 1rem);
  min-height: 4rem;
  margin-left: 1rem;

  .byggtjanst-icon {
    width: 150px;
  }
}

.top-bar-profile {
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  .dropdown-menu {
    min-width: 260px;
    max-width: 260px;
  }
}

@include media-breakpoint-down(sm) {
  .top-bar {
    position: fixed;
    top: 0;
    height: 4rem;
    z-index: 2;
    background: white;
    margin-left: 0;
    width: 100%;

    .dropdown-menu {
      min-width: 240px;
      max-width: 240px;
    }

    .byggtjanst-icon {
      margin-right: 14px;
    }
  }
}
