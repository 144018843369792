@import '../../lib/shared-assets/styles/_variables.scss';

.btn {
  box-shadow: $btn-box-shadow;
  .spinner-border {
    position: relative;
    bottom: 0.25em;
    margin-right: 0.5em;
  }
  &.btn-link {
    box-shadow: none;
    color: $byggtjanst-gray-nine;
  }
  &.btn-text {
    padding: 0;
  }
  &.btn-primary {
    color: $byggtjanst-gray-one;
    border-radius: 8px;
    border-color: transparent;
  }
  &.btn-outline-primary {
    box-shadow: none;
  }
  &.btn-primary,
  &.btn-outline-primary {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $byggtjanst-gray-one;
    }

    &:hover {
      background-color: $byggtjanst-lightgreen;
    }
    &:focus,
    &:active {
      background-color: $byggtjanst-darkgreen;
    }
    &:active:focus {
      box-shadow: none;
    }
  }
  &.btn-outline-secondary {
    color: $byggtjanst-gray-four;
    border-color: $byggtjanst-gray-four;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $byggtjanst-gray-one;
    }

    &:hover {
      background-color: $byggtjanst-gray-three;
    }
    &:focus,
    &:active {
      background-color: $byggtjanst-gray-five;
    }
    &:focus,
    &:active:focus {
      box-shadow: none;
    }
  }
}
