@import 'lib/shared-assets/styles/_variables.scss';

.specification-list-item {
  background-color: #fff;
}
li.list-group-item.specification-list-item {
  padding: 0.65em 0 0.65em 0;
}

.lh-21 {
  line-height: 21px;
}
.list-item-content {
  min-width: 400px;

  .project-name {
    min-width: 200px;
    max-width: 200px;
    color: $byggtjanst-gray-mid;
  }
  .status-name {
    min-width: 140px;
    max-width: 140px;
    line-height: 21px;
  }
  .date-text {
    padding-right: 2em;
    line-height: 21px;
  }
}

.icon-wrapper {
  padding-left: 1.5em;
  padding-right: 0.5em;
}
