@import '../../lib/shared-assets/styles/_variables.scss';

.form-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.form-image {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.img-thumbnail.form-changed {
  border-color: $byggtjanst-primary;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  border: 0.5px solid #8b8f96;
  border-radius: 8px;
  min-height: calc(255px - 2.25rem);
}

.btn-image {
  &.button-edit {
    padding: 0;
    border: 0;
    box-shadow: none;
    color: $byggtjanst-gray-three;
  }
  &:hover {
    color: $byggtjanst-gray;
  }
}
.image-edit-buttons {
  margin-top: -1.25rem;
}

.image-edit-inline {
  .image-edit-buttons {
    position: absolute;
    margin-top: 0;
    top: 1rem;
    right: 1rem;
    z-index: 3;
    svg {
      color: white;
      filter: drop-shadow(1px 1px 0.7px black);
      &:hover {
        color: $byggtjanst-gray-mid;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .placeholder-image {
    min-height: calc(200px - 2.25rem);
  }
}
