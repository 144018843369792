@import '../../lib/shared-assets/styles/_variables.scss';

.form-image-container {
  .progress {
    height: 2rem;
    margin-right: 1rem;
    .progress-bar {
      background-color: $byggtjanst-darkblue;
    }
  }
}
