@import '../../lib/shared-assets/styles/_variables.scss';

.popover-ref {
  z-index: 3;
}

.popover {
  font-family: $byggtjanst-font-family;
  font-size: $base-font-size;
  min-width: 212px;
  box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
  border-radius: 8px;
  z-index: 2;
  visibility: hidden;

  &.open {
    visibility: visible;
  }
}

.bubble {
  display: block;
  min-width: 310px !important;

  &::before {
    content: '\00a0';
    display: block;
    height: 1.5em;
    position: absolute;
    top: -6px;
    -moz-transform: rotate(42deg) skew(305deg);
    -ms-transform: rotate(42deg) skew(305deg);
    -o-transform: rotate(42deg) skew(305deg);
    -webkit-transform: rotate(42deg) skew(305deg);
    transform: rotate(42deg) skew(306deg);
    width: 1.65em;
    background-color: #ffffff;
    float: left;
    left: -0.4em;
    border-left: 2px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    border-bottom-left-radius: 8px;
  }
}
