@import '../../lib/shared-assets/styles/variables';

.modal--feedback-action,
.modal--feedback-form {
  .btn {
    box-shadow: $btn-box-shadow;
    .spinner-border {
      position: relative;
      bottom: 0.25em;
      margin-right: 0.5em;
    }
    &.btn-link {
      box-shadow: none;
    }
    &.btn-text {
      padding: 0;
    }
    &.btn-outline-primary {
      background-color: #ffffff;
      box-shadow: none;
    }
    &.btn-outline-primary {
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: #ffffff;
        border: none;
      }

      &:hover {
        background-color: $byggtjanst-lightgreen;
      }
      &:focus,
      &:active {
        background-color: $byggtjanst-darkgreen;
      }
      &:active:focus {
        box-shadow: none;
      }
    }
  }
}
