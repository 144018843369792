@import '../../lib/shared-assets/styles/variables';

#feedback {
  position: fixed;
  bottom: 3rem;
  right: Max(20px, calc(100vw - 1400px + 3rem));
  background: transparent;
  z-index: 1202;

  .btn {
    &:focus {
      box-shadow: none;
    }
  }

  .btn-feedback {
    background: $byggtjanst-darkgreen;
    border-radius: 35px;
    width: 54px;
    height: 56px;
    color: #fff;
    display: flex;

    &--active {
      align-items: center;
      justify-content: end;
    }

    &:active:focus {
      box-shadow: none;
    }

    .feedback-chat {
      position: absolute;
      top: 14px;
      right: 7px;
      font-size: 1.5rem;
    }

    .feedback-help {
      position: absolute;
      left: 10px;
      bottom: 2px;
      transform: rotate(-20deg);
      font-size: 1.75rem;
    }

    .feedback-close {
      display: flex;
      flex: 1 1 auto !important;
      width: 28px;
      height: 28px;
    }
  }
}

.feedback-actions {
  position: absolute;
  top: -75px;
  width: calc(100% - 3.5rem);

  .feedback-actions__item {
    background: #ffffff;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    padding: 1.75rem;
    margin-bottom: 1.75rem;
    border-top: 2px solid $byggtjanst-lightgreen;

    &__title {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.75rem;
    }

    &__text {
      color: $byggtjanst-gray-five;
      margin-bottom: 1rem;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    &--feedback-button {
      width: 100%;
      min-height: 44px;
      border-radius: 8px;
    }
  }
}

.btn-link {
  &--feedback-form {
    color: $byggtjanst-darkgreen;
    &:hover {
      color: $byggtjanst-darkgreen;
    }
  }

  &--feedback-back {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }

  &--error {
    color: $byggtjanst-red;
    text-decoration: underline;
    padding: 0.2rem;

    &:hover {
      color: $byggtjanst-red;
    }
  }
}

.feedback-form-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  form {
    width: 100%;
  }

  .form-control {
    &--feedback-form {
      border: none;
      resize: none;
      outline: none;
      box-shadow: none;
      padding: 0.375rem 0;
      width: calc(100% - 3rem);
    }
  }

  .feedback-submit {
    display: flex;
    justify-content: flex-end;
  }
}

.feedback-submit-container {
  overflow-wrap: break-word;
  min-height: 62px;

  &__status {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__text {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }

    &.status--success {
      color: $byggtjanst-darkgreen;
    }

    &.status--error {
      color: $byggtjanst-red;
    }
  }
}
