@import 'lib/shared-assets/styles/_variables.scss';

.modal-body {
  min-height: 4em;
}

.modal {
  margin: 0 auto;
  &.show {
    display: block;
  }
  &.dropdown-item,
  &.dropdown-item:hover,
  &.dropdown-item:focus {
    background: none !important;
  }
}

.modal-dialog {
  height: 100%;
}

.modal-content {
  box-shadow: 0 4px 4px 0px #bebebe40;
  border-radius: 8px;
}

.modal-header {
  align-items: unset;
  border-bottom: none;
}

.modal-body {
  padding: 1.75rem;
}

hr {
  border-top: 1px solid;
  width: 95%;
}

.modal-icon {
  &.gray-three {
    color: $byggtjanst-gray-three;
    font-size: 3.5rem;
  }
}

.modal-footer {
  .btn {
    min-width: 200px;
  }
}

@include media-breakpoint-down(sm) {
  .modal-dialog,
  .modal-content {
    min-width: auto;
  }
}
