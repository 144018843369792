@import '../../lib/shared-assets/styles/_variables.scss';

.sb-select {
  font-size: 1rem;

  &__control {
    width: 100%;
    border-radius: 8px;
    border: 0.5px solid #8b8f96;
    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
    padding: 0;
    line-height: 40px;
    background: #ffffff;

    &.open {
      border-bottom: 0px;
      border-radius: 8px 8px 0px 0px;
      box-shadow: none;
    }
  }

  &__selected {
    padding-top: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;

    &.open {
      margin-top: 0.6rem;
      &:hover {
        background: $byggtjanst-gray-two;
      }
    }
  }

  &__icon-selected,
  &-option__icon-selected {
    color: $byggtjanst-primary;
  }

  &__options {
    width: 100%;
    border-radius: 0px 0px 8px 8px !important;
    border-top: 0px;
    border-left: 0.5px solid #8b8f96;
    border-right: 0.5px solid #8b8f96;
    border-bottom: 0.5px solid #8b8f96;

    &.open {
      padding-top: 0;
    }
  }

  &__text,
  &-option__text {
    font-weight: bold;
  }

  &-option {
    cursor: pointer;
    line-height: 40px;
    overflow: hidden;

    &.active {
      background: $byggtjanst-gray-two;
    }
  }
}
