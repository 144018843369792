@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

$base-font-size: 14px;
$body-text-font-size: 12px;

$header-navbar-height: 52px;
$header-navbar-ama-logo: 160px;

$navigator-item-height: 35px;
$navigator-item-border-width: 4px;
$navigator-item-border-parent-outer-width: 1px;

$canvas-header-height: 48px;
$canvas-text-header-height: 53px;
$canvas-footer-height: 36px;
$canvas-structure-item-border-width: 4px;
$canvas-structure-item-height: 45px;

$canvas-selected-border-width: 3px;

$text-a4-width: 21cm;
$text-a4-margin: 2.54cm;

$byggtjanst-primary: #008746;
$byggtjanst-darkgreen: #006638;
$byggtjanst-lightgreen: #00a952;
$byggtjanst-interactivegreen: #00cc5f;
$byggtjanst-red: #d51317;
$byggtjanst-orange: #f39313;
$byggtjanst-lightblue: #73b8d2;
$byggtjanst-blue: #2f80ed;
$byggtjanst-darkblue: #24608b;
$byggtjanst-lime: #95c11f;
$byggtjanst-byggjura: #e74c3c;

// These colors are taken from the latest assets colors
$byggtjanst-gray-light: #e7e7e7;
$byggtjanst-gray-mid: #9a9a9a;
$byggtjanst-gray: #757575;

// Canvas gray is different. Don't ask me why
$canvas-gray: #d9d9d9;

/*
 These colors are copied from byggtjanst.se-repo
 and merged with the latest assets colors
 */
$byggtjanst-gray-one: #f5f5f5;
$byggtjanst-gray-two: $byggtjanst-gray-light;
$byggtjanst-gray-three: #bebebe;
$byggtjanst-gray-four: #8b8f96;
$byggtjanst-gray-five: $byggtjanst-gray;
$byggtjanst-gray-six: #3c4248;
$byggtjanst-gray-eight: #1c2227;
$byggtjanst-gray-nine: #333333;

$byggtjanst-primary-palette: (
  50: $byggtjanst-interactivegreen,
  100: $byggtjanst-interactivegreen,
  200: $byggtjanst-lightgreen,
  300: $byggtjanst-lightgreen,
  400: $byggtjanst-primary,
  500: $byggtjanst-primary,
  600: $byggtjanst-primary,
  700: $byggtjanst-darkgreen,
  800: $byggtjanst-darkgreen,
  900: $byggtjanst-darkgreen,
  A100: #89ffa8,
  A200: #56ff83,
  A400: #23ff5e,
  A700: #09ff4b,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

// Auto generated palette from base $byggtjanst-red
$byggtjanst-red-palette: (
  50: #fae3e3,
  100: #f2b8b9,
  200: #ea898b,
  300: #e25a5d,
  400: #db363a,
  500: $byggtjanst-red,
  600: #141212,
  700: #ca0e11,
  800: #c40b0d,
  900: #ba0607,
  A100: #ffe4e4,
  A200: #ffb1b1,
  A400: #ff7e7f,
  A700: #ff6465,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-colors: (
  'primary': $byggtjanst-primary,
  'success': $byggtjanst-darkblue,
  'danger': $byggtjanst-red,
  'warning': $byggtjanst-orange,
  'info': $byggtjanst-darkblue,
  'gray': $byggtjanst-gray,
  'blue ': $byggtjanst-darkblue,
  'secondary': $byggtjanst-gray,
  'green': $byggtjanst-primary,
);

$link-color: $byggtjanst-darkblue;
$gray-100: $byggtjanst-gray-one;
$gray-200: $byggtjanst-gray-two;
$gray-300: $byggtjanst-gray-three;
$gray-400: $byggtjanst-gray-four;
$gray-500: $byggtjanst-gray-five;
$gray-600: $byggtjanst-gray-six;
$gray-700: $byggtjanst-gray-eight; // seven ate nine
$gray-800: $byggtjanst-gray-eight;
$gray-900: $byggtjanst-gray-nine;
$text-muted: $byggtjanst-gray;

$btn-line-height: 1.75;
$btn-border-radius: 0;
$modal-content-border-radius: 0;
$border-radius: 0.5rem;
$card-cap-bg: $byggtjanst-gray-one;
$box-shadow: 0px 2px 4px rgba(#000, 0.25);

$btn-link-color: $byggtjanst-darkblue;
$byggtjanst-font-family: 'Inter', sans-serif;
$font-family-sans-serif: 'Inter', sans-serif;
$font-family-bold: 'Inter', sans-serif;
$font-family-light: 'Inter', sans-serif;
$font-family-regular: 'Inter', sans-serif;

$link-decoration: none;
$studio-transition-duration: 0.5s;
$dropdown-bg: white;
$card-border-radius: 0.75rem;
$card-border-width: 0;
$modal-content-bg: $byggtjanst-gray-one;
$body-bg: $byggtjanst-gray-one;
$zindex-modal-backdrop: 1200;
$zindex-modal: 1201;
$btn-border-radius: 0.5em;
$btn-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.5);
$list-group-border-radius: 0.5em;
$pagination-color: $byggtjanst-blue;
$gutter-width: 3rem;
$gutter-width-mobile: 0.5rem;
