@import '../../lib/shared-assets/styles/_variables.scss';

.avatar {
  color: $byggtjanst-gray;
  background: $byggtjanst-gray-one;
  border-radius: 12px;
  font-size: $base-font-size;
  width: 24px;
  height: 24px;
  font-family: $byggtjanst-font-family;

  &.text-small {
    font-size: $body-text-font-size;
  }
}

.avatar-inverted {
  color: $byggtjanst-gray-one;
  background: $byggtjanst-gray-three;

  &.lime {
    background: $byggtjanst-lime;
  }

  &.s-36-px {
    width: 36px;
    height: 36px;
    border-radius: 20px;
  }
}

.avatar-badge {
  color: $byggtjanst-gray;
  font-size: 0.75rem;
  padding: 0.125rem 0.5rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-family: $font-family-light;
}

.avatar-profile {
  .text-small {
    font-size: 12px;
    max-width: 190px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
