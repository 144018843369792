@import 'lib/shared-assets/styles/_variables.scss';

.project-card-image {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .empty {
    background-color: #e7e7e7;
    fill: rgb(254, 254, 254);
    fill-opacity: 1;
    fill-rule: nonzero;
    stroke: none;
  }
}

.card {
  &.project-card {
    border: 0;
    margin-right: 1rem;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    height: 20rem;
    min-width: 28rem;

    &.scroll {
      box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
      flex-shrink: 0;
      scroll-snap-align: start;
      scroll-margin-right: 1rem;
      transition: transform 0.5s, box-shadow 0.15s ease-in-out;

      &:hover {
        box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
      }

      a {
        color: $byggtjanst-gray-nine;
      }
    }

    .button-edit {
      padding: 0;
      border: 0;
      box-shadow: none;

      svg {
        filter: none;
      }
    }

    .project-description {
      max-height: 3rem;
      overflow: hidden;
    }
  }
}

.card-body {
  background-color: #fff;
  p,
  div,
  span {
    &.text-small {
      font-size: $body-text-font-size;
    }
    &.text-muted {
      color: $byggtjanst-gray-four !important;
      font-family: $byggtjanst-font-family; /*$font-family-light; */
      font-weight: 500;
    }
  }
}

@include media-breakpoint-down(sm) {
  .card {
    &.project-card {
      margin-right: 0.25rem;
      max-width: calc(100vw - 2rem);
    }
  }
}
