@import 'lib/shared-assets/styles/_variables.scss';

.create-specification-modal{
  .modal-dialog,
  .modal-content {
    min-height: 680px;
    max-width: 662px;
  }
}

.modal {

  &.hide{
    opacity: 0 !important;
  }
  &.create-specification-disabled-modal {
    &__content {
      padding-top: 4rem !important;
      padding-bottom: 2rem;
    }

    .modal-dialog,
    .modal-content {
      min-width: 662px;
    }

    .modal-header {
      min-height: 281px;
    }

    .modal-header-divider {
      display: none;
    }

    .modal-icon {
      font-size: 3.5rem;
      margin-bottom: 1rem;

      &--primary {
        color: $byggtjanst-primary;
      }

      &--warning {
        color: $byggtjanst-orange;
      }
    }

    .license {
      color: #000000;
      font-size: 1.5rem;
    }

    .modal-title {
      max-width: 400px;
    }

    .modal-body {
      min-height: 0;
      padding: 0;
    }
  }
}