.iframe-container {
  width: 100%;
  position: relative;

  &:first-child {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
