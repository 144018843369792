@import url(https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10,100..900;0,100..900&display=swap);
@import 'lib/shared-assets/styles/_custom-bootstrap.scss';

h1 {
  color: $byggtjanst-gray-eight;
}

.studio-page {
  min-width: 0;
  width: 100%;
  box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.125);

  .studio-page-title {
    margin-bottom: 2rem;
  }
}

.studio-content {
  width: Min(100%, calc(1360px + #{$gutter-width}));
  background: $byggtjanst-gray-one;
}

.project-description {
  font-size: 0.75rem;
  color: $byggtjanst-gray-six;
}

.project-number {
  font-size: 0.75rem;
  color: $byggtjanst-gray-six;
  font-family: $font-family-light;
}

.studio-date {
  color: $byggtjanst-gray;
  font-size: 0.75rem;
}

.page-container.container {
  padding: 2rem $gutter-width 6rem $gutter-width;
  overflow: hidden;
}
#feedback {
  right: 20px !important;
  z-index: 0 !important;
}

.modal-dialog--feedback-action {
  height: auto !important;
}
.modal-dialog--feedback-form {
  height: auto !important;
}

@include media-breakpoint-up(sm) {
  .modal--feedback-action .modal-dialog-bottom,
  .modal--feedback-form .modal-dialog-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

@include media-breakpoint-down(sm) {
  .page-container.container {
    padding: 6rem $gutter-width-mobile 6rem $gutter-width-mobile;
  }
}

.navbar .modal {
  .container,
  .container-fluid {
    display: block;
  }
}

.text-primary {
  color: $byggtjanst-primary !important;
}

.bg-info {
  color: #fff;
  background-color: $byggtjanst-darkblue !important;
}

.btn-link {
  color: $byggtjanst-gray-nine;

  &:hover {
    color: $byggtjanst-gray-nine !important;
  }
}

html {
  font-size: $base-font-size;
}

body {
  font-family: $byggtjanst-font-family;
  color: $byggtjanst-gray-nine;
}

a {
  color: $byggtjanst-gray-nine;
}

a:hover {
  color: $byggtjanst-gray-nine;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: $font-family-sans-serif;
  margin-bottom: 0;
}

small,
.small {
  font-family: $font-family-light;
  font-size: 0.75rem;
}

.list-group-item {
  border-radius: $list-group-border-radius;

  & + & {
    border-top-width: $list-group-border-width !important;
    margin-top: 0.25em;
  }
}

p {
  max-width: 700px;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-family: $font-family-light;
  font-size: 0.75rem;
}

b {
  font-family: $font-family-bold;
  font-family: $font-family-sans-serif;
  font-weight: normal;
}

.card {
  border: 1px solid transparent;
  transition: filter 0.05s linear;
  box-shadow: 3px 3px 9px -1px rgba(0, 0, 0, 0.25);
}
.card-header {
  background-color: $card-bg;
}
.panel {
  margin-bottom: 2em;
}
.form-group {
  margin-bottom: 1em;
}
.page-container {
  height: 100%;

  &.container {
    max-width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
}
.color-black {
  color: black;
}
.color-gray {
  color: $byggtjanst-gray;
}
.cursor-pointer {
  cursor: pointer;
}

.flex-basis-1 {
  flex-basis: 2em;
}

.flex-basis-2 {
  flex-basis: 2em;
}

.flex-basis-3 {
  flex-basis: 3em;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-shrink-3 {
  flex-shrink: 3;
}

.text-truncated {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mobile-only {
  display: none;
}

.shrinkable {
  min-width: 0;
}

.gutter-right {
  margin-right: $gutter-width;
}

.cookiefirst-root > span {
  bottom: 140px !important;
}

.modal-mw-993 {
  .modal-content {
    min-width: 932px;
  }
  .modal-dialog {
    min-width: 932px;
  }
}

@include media-breakpoint-down(md) {
  .large-only {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .gutter-right {
    margin-right: $gutter-width-mobile;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-start {
    order: -1000;
  }

  .mobile-end {
    order: 1000;
  }
}

input,
input:focus,
.form-control:focus,
.form-control {
  background-color: #fff;
}
