@import '../../lib/shared-assets/styles/_variables.scss';

.dropdown {
  font-family: $byggtjanst-font-family;

  .btn {
    box-shadow: none;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }
  .dropdown-menu {
    position: absolute;
    transition: visibility 0.2s, opacity 0.15s ease-out;
    visibility: hidden;
    display: block;
    opacity: 0;
    background: $dropdown-bg;
    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
    border-radius: 8px;
    .dropdown-item {
      color: $byggtjanst-gray-nine;
      text-align: left;

      &:hover,
      &:focus {
        background-color: $byggtjanst-gray-two;
      }
    }
    &.open {
      left: unset;
      visibility: visible;
      display: block;
      opacity: 1;
    }
    .lh-dropdown-item {
      line-height: 40px;
    }
  }
  &[data-popper-reference-hidden*='true'] {
    visibility: hidden;
    pointer-events: none;
  }
}
