@import '../../lib/shared-assets/styles/_variables.scss';
.notification-dialog {
  max-width: 662px;
  max-height: 263px;
}

.notification-modal {
  max-width: 662px;
  max-height: 263px;
  margin: 0 auto;

  .modal-header {
    border-bottom: 0 !important;
  }
}
