@import 'lib/shared-assets/styles/_variables.scss';

.participant-edit-modal {
  &__header {
    margin-top: 5rem;
  }

  &__text {
    max-width: 402px;
  }

  &__label {
    margin-bottom: 1rem;
  }

  &__footer {
    margin-top: 3rem;
  }

  .modal-dialog,
  .modal-content {
    min-height: 680px;
    max-width: 663px;
  }

  .modal-content {
    background: #ffffff;
  }

  .btn[type='submit'] {
    min-width: 200px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border: none;
    }
  }
}
