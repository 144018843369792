@import '../../lib/shared-assets/styles/_variables.scss';

.project-list-item {
  max-height: 48px;
  min-height: 48px;
  background-color: #fff;
}
.project-list-itemwrap {
  min-height: 37px;
}
.list-divider {
  margin-top: 3.5em;
}
.projectnumber {
  min-width: 11em;
}
.edit-btn {
  padding: 0 10px 5px 0;
}
