@import 'lib/shared-assets/styles/_variables.scss';

.card-create-project-button {
  margin-right: 4rem;
}

.link-all-project {
  color: $byggtjanst-gray-nine;

  &:hover {
    color: $byggtjanst-gray-nine;
  }
}

.projects-showmore {
  width: 0;
  height: 20rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;

  &.scroll {
    position: absolute;
    top: 0;
    z-index: 2;

    &.scroll-left {
      left: 40px;
    }

    &.scroll-right {
      right: 50px;
    }
  }

  .projects-showmore-link {
    width: 3rem;
    font-size: 0.75rem;
  }

  .showmore-text {
    display: none;
  }

  .btn-scroll {
    min-width: 120px;
    min-height: 120px;
    background: rgba(200, 200, 200, 0.5);
    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
    border-radius: 8px;
    color: #ffffff;

    &:hover {
      background: rgba(200, 200, 200, 0.8);
    }

    &:disabled {
      /* Increase the specificity */
      background-color: $byggtjanst-gray-one;
      color: $byggtjanst-gray-light;
    }

    .projects-showmore-icon {
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
}

.projects-spacer {
  border: 0;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex: 1 0;
  min-width: 17rem;
  max-width: 30rem;
  list-style: none;
}

.project-card-list {
  margin-right: 4rem;
  margin-left: 0rem;

  &.scroll {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
}

.pagination-dots {
  list-style-type: none;
  margin: 0;
  overflow: hidden;

  li {
    margin-left: 13px;
    height: 12px;
    width: 12px;
    background-color: $byggtjanst-gray-three;
    border-radius: 50%;
    display: inline-block;
    &.active {
      background-color: white;
      border: 1px solid $byggtjanst-gray-three;
    }
  }
}

@include media-breakpoint-down(sm) {
  .card-create-project-button {
    margin-right: 0;
  }

  .project-card-list {
    margin-left: 0;
    margin-right: 0;
  }

  .projects-showmore {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;

    &.scroll {
      width: 60px;
      height: 20px;
      top: calc(50% - 1.5rem);

      &.scroll-left {
        left: 20px;
      }

      &.scroll-right {
        right: 20px;
      }
    }

    .projects-showmore-link {
      width: auto;
    }

    .showmore-text {
      padding-left: 0.5rem;
      display: block;
      font-size: 1rem;
    }

    .btn-scroll {
      min-width: 60px;
      min-height: 20px;
    }
  }
}
