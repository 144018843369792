@import 'lib/shared-assets/styles/_variables.scss';

.backdrop-transparent{
  background-color: transparent;
}
.modal--ama-fs-templates {
  .modal-icon {
    font-size: 4.5rem;
  }
  .modal-body {
    padding: 0 1.75rem 1.75rem;
  }
  .modal-dialog,
  .modal-content {
    min-height: 600px;
  }
  
}
