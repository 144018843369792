@import '../../lib/shared-assets/styles/variables';

.modal--feedback-action,
.modal--feedback-form {
  .modal-dialog {
    max-width: 400px;
    min-width: 400px;
    min-height: 500px;
  }

  .modal-content {
    max-width: 400px;
    min-width: 400px;
    min-height: 500px;
    box-shadow: 0 0 35px #555;
    border-radius: 8px;

    &--feedback-action,
    &--feedback-form {
      border: none;
      border-radius: 20px 20px 8px 8px;
      box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
      transition: transform 0.5s, box-shadow 0.15s ease-in-out;
      &:hover {
        box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
      }
    }
  }

  .modal-header {
    align-items: unset;
    border-bottom: none;

    &--feedback-action,
    &--feedback-form {
      background: $byggtjanst-darkgreen;
      border: none;
      color: #ffffff;
      border-radius: 20px 20px 0px 0px;
      padding: 1.5rem;
    }

    &--feedback-action {
      min-height: 210px;
    }

    &__text {
      font-family: $font-family-light;
    }
  }

  .modal-title {
    &--feedback-action,
    &--feedback-form {
      margin-bottom: 1rem;
    }
  }

  .modal-body {
    padding: 1.75rem;

    &--feedback-action {
      min-height: 282px;
    }
  }

  .modal-header-divider {
    border-bottom: 1px solid #e0e0e0;
    margin: 0 1.75rem;
  }

  .modal-icon {
    &.gray-three {
      color: $byggtjanst-gray-three;
      font-size: 3.5rem;
    }
  }

  .modal-footer {
    &--feedback-action,
    &--feedback-form {
      border: none;
      border-radius: 0 0 8px 8px;
      background: #ffffff;
      padding: 1rem 1.75rem;

      .btn-link {
        &--feedback-form {
          min-width: auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .modal--feedback-action,
  .modal--feedback-form {
    .modal-dialog-bottom {
      position: absolute;
      left: calc(1360px - 400px);
      bottom: 110px;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .modal--feedback-action,
  .modal--feedback-form {
    .modal-dialog-bottom {
      position: absolute;
      right: 115px;
      bottom: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .modal--feedback-action,
  .modal--feedback-form {
    .modal-dialog,
    .modal-content {
      min-width: auto;
    }

    .modal-dialog-bottom {
      position: unset;
    }
  }
}
