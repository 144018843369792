.specification-form-header{
    p {
        max-width: 400px;
    }
}

.specification-form-select {
    min-height: 3rem;
}

.specification-form-buttons {
    .btn {
      min-width: 200px;
    }
  }