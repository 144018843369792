@import '../../lib/shared-assets/styles/variables';

.loginpage {
  font-family: $font-family-sans-serif;
  font-size: 1.15rem;
  width: 100%;

  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 4rem;
    z-index: 3;

    & > .byggtjanst-icon {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 3;

      &.desktop-only {
        margin: 2px 24px;
        padding: 0.71em 0;
      }
    }

    .mobile-only {
      svg {
        width: 150px;
      }
    }
  }

  .loginpage-container {
    top: 200px;
  }
}
