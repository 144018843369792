@import 'lib/shared-assets/styles/_variables.scss';
.project-info-panel {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
}

.project-image {
  display: flex;
  flex: 1 1 1rem;
  min-width: 20rem;
  height: 16rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.project-info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1 1 1rem;
  min-width: 20rem;
  height: 16rem;

  p {
    font-size: 0.9rem;
  }
}

.project-logotype {
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.logotype-placeholder {
  border: 1px dashed $byggtjanst-gray-light;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
