@import 'lib/shared-assets/styles/_variables.scss';

.specification-accordion {
  margin: 1rem 0 !important;

  &:first-child {
    margin-top: 0 !important;
  }

  &.MuiAccordion-root {
    &:before {
      display: none;
    }
  }

  &.MuiAccordion-rounded,
  &.MuiAccordion-rounded:first-child,
  &.MuiAccordion-rounded:last-child {
    border-radius: 8px;
  }

  &.MuiPaper-root {
    color: $byggtjanst-gray-nine;
  }

  &.MuiPaper-elevation1 {
    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
    transition: transform 0.5s, box-shadow 0.15s ease-in-out;
  }

  &:hover {
    box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
  }

  .MuiSvgIcon-root {
    color: #333333;
  }

  .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiAccordionSummary-root {
    padding: 0 12px;
    align-items: flex-start;

    &.Mui-expanded {
      min-height: auto;
    }
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 9px 0;
    max-width: calc(100% - 3rem);
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(-180deg) !important;
  }

  &__content {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex: auto;
  }

  &__body {
    display: flex;
    flex: auto;
    justify-content: space-between;
    max-width: 100%;
    align-items: center;
  }

  &__details {
    display: flex;
    justify-content: space-between;

    &--main {
      flex-direction: column;
      flex-grow: 0.8;
      max-width: 40%;
    }

    &--extra {
      flex-grow: 1;
      max-width: 45%;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    flex: auto;

    &__content {
      padding-top: 5px;
    }
    &__details {
      margin-bottom: 12px;
    }
    &__label--bold {
      margin-bottom: 12px;
    }
    &__link {
      display: flex;
      flex: auto;
      justify-content: flex-end;

      a {
        color: #000000;
        text-decoration: underline;

        &:hover {
          color: #000000;
        }
      }
    }
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--title {
      font-size: 16px;
      color: #000000;
    }

    &--small {
      font-size: 14px;
      color: $byggtjanst-gray-nine;
      line-height: 24px;
    }

    &--muted {
      color: $byggtjanst-gray-four;
    }
  }

  &__label,
  &-item__label {
    &--bold {
      font-weight: bold;
    }
  }

  &-participants {
    margin-top: 10px;
    border-top: 0.5px solid #bebebe;
    border-radius: 0 !important;

    &.MuiAccordion-root.Mui-expanded {
      margin-top: 10px !important;
    }

    &.MuiPaper-elevation1 {
      box-shadow: none !important;
    }

    &__details {
      display: flex;
      flex: auto;
      flex-direction: column;
    }

    &__item {
      display: flex;
      flex: auto;
      align-items: center;
      padding: 0.5rem 0rem;
    }

    &__info {
      display: flex;
      flex: auto;
      justify-content: space-between;
      margin-left: 10px;

      &-text {
        display: flex;

        &--grow {
          flex: auto;
          max-width: 60%;
        }
      }
    }

    &__label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &--bold {
        font-weight: bold;
      }
    }

    .MuiAccordionSummary-root {
      padding: 0;
      align-items: center;
    }

    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px 8px 8px 16px;
  }
}

.modal--ama-fs-create {
  &.hide {
    opacity: 0 !important;
  }
  .modal-dialog,
  .modal-content {
    min-width: Min(calc(100% - 2rem), 1241px);
    min-height: Min(calc(100% - 2rem), 931px);
  }

  .modal-content {
    background: #ffffff;
  }

  .modal-body {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
