@import '../../lib/shared-assets/styles/_variables.scss';

.specification-card-description {
  font-size: 0.75rem;
  line-height: 1.5;
  height: calc(3 * 1.5 * 0.75rem);
  overflow: hidden;
  font-family: $font-family-light;
}

.specification-project-name {
  font-size: 1rem;
  color: $byggtjanst-gray-mid !important;
  font-family: $font-family-light;
}

.specification-item {
  .specification-project-name {
    font-size: 0.9rem;
    font-family: $byggtjanst-font-family;
    color: $byggtjanst-gray-six;
  }
}

.specification-card-divider {
  height: 0;
  margin: 0 1rem;
  overflow: hidden;
  border-top: 1px solid $dropdown-divider-bg;
}
