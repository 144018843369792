@import 'lib/shared-assets/styles/_variables.scss';
.form-control.form-changed {
  border-color: $byggtjanst-primary;
}

.project-form {
  .project-info-top {
    min-height: 111px;
    align-items: center;
  }
  .project-name {
    max-width: 60%;
    padding-right: 2rem;
  }
  .project-info-group {
    max-width: 40%;

    .project-number {
      font-family: $byggtjanst-font-family;
      padding-left: 2rem;
      padding-right: 4rem;
    }
  }
  .project-input {
    border-color: $byggtjanst-gray-four;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);

    &::placeholder {
      color: $byggtjanst-gray-three;
      font-size: 1rem;
    }
  }
  .project-info-bottom {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    max-height: 255px;
    padding-bottom: 0.25rem;
    width: 100%;

    .project-form-image {
      padding-right: 2rem;

      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    .project-form-description {
      padding-left: 2rem;
    }

    .project-form-image,
    .project-form-description {
      max-width: 50%;
    }
  }

  label {
    font-size: 1.125rem;
    font-weight: bold;
  }

  .button-edit {
    padding: 0;
    border: 0;
  }

  textarea.form-control {
    min-height: calc(255px - 2.25rem);
  }

  .project-form-buttons {
    .btn {
      min-width: 200px;
    }
  }
}

.contain-image {
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .project-form {
    margin-top: 2rem;
    .project-info-top {
      flex-direction: column;
      align-items: unset;
      max-width: unset;
    }
    .project-name {
      max-width: unset;
      padding-right: 0;
      margin-bottom: 2rem;
    }
    .project-info-group {
      max-width: unset;
      margin-bottom: 2rem;

      .project-number {
        font-family: $byggtjanst-font-family;
        padding-left: 0;
        padding-right: 4rem;
      }
    }
    .project-info-bottom {
      flex-direction: column;
      flex-wrap: unset;
      max-height: unset;

      .project-form-image {
        padding-right: 0;
        margin-bottom: 2rem;
      }

      .project-form-description {
        padding-left: 0;
      }

      .project-form-image,
      .project-form-description {
        max-width: unset;
      }
    }

    textarea.form-control {
      min-height: calc(200px - 2.25rem);
    }
  }
}
