@import 'lib/shared-assets/styles/_variables.scss';

.specification-in-progress {
  .last-change {
    color: $byggtjanst-darkblue;
  }
}
.list-spacer {
  margin-bottom: 4.5em;
}
.list-title {
  padding-top: 3.5em;
}

.empty-items-box {
  border: 5px dashed #e7e7e7;
  min-height: 60px;
  flex: 0 0 100%;
  border-radius: 4px;
  max-width: 920px;
}

.empty-items-content {
  max-inline-size: 600px;
}
