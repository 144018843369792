@import '../../../lib/shared-assets/styles/_variables.scss';
$navbar-margin-left: 24px;
$navbar-indicator-width: 4px;

.navbar-container {
  z-index: 3;

  .navbar-top {
    padding: 0.71em 0;
    margin-left: 21px;
    border-top: 0;
    border-left: 0;

    &.expanded {
      margin-left: $navbar-margin-left;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-container {
    width: 0;
    position: fixed;
  }
}

.studio-navbar-expand {
  width: 74px;
  min-height: 100vh;
  transition: width $studio-transition-duration;
  &.expanded {
    width: 263px;

    .dropdown {
      button.btn {
        &.dropdown-opener {
          min-width: 222px;
          border-radius: 8px;
          justify-content: unset;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          min-height: 44px;
          svg {
            margin-right: 0.5rem;
          }

          .dropdown-btn-text {
            display: flex;
          }
        }
      }
      .dropdown-menu {
        min-width: 262px;
      }
    }
  }
}

.studio-navbar {
  position: fixed;
  height: 100%;
  top: 0;
  overflow: hidden;
  box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.125);

  .group {
    margin: 12px 0 0 0;
    opacity: 0;
    transition: opacity $studio-transition-duration;
  }
  .navbar-item {
    padding-left: ($navbar-margin-left - $navbar-indicator-width);
    &.divider {
      padding-right: ($navbar-margin-left - $navbar-indicator-width);
    }
    > * {
      padding: 9px 0;
    }
    > .divider {
      padding: 0;
    }
  }

  > li {
    margin: 2px 0;
    width: 100%;
    border-left: 4px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    &.active {
      border-left: $navbar-indicator-width solid $byggtjanst-primary;
      a,
      span {
        color: $byggtjanst-primary;
      }
    }
    &.interactive {
      transition: all 0.5s ease;
      &:focus,
      &:hover {
        background-color: $byggtjanst-gray-light;
      }

      a:hover {
        color: $byggtjanst-gray-eight;
      }
    }

    a {
      color: $byggtjanst-gray-eight;
    }
  }

  .dropdown {
    margin: 11px 0;
    button.btn {
      &.dropdown-opener {
        width: 25px;
        height: 25px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        margin-left: 1px;

        .dropdown-btn-text {
          display: none;
        }
      }
    }
  }

  .byggtjanst-icon {
    width: 180px;
    .byggtjanst-logo-text {
      opacity: 0;
      transition: opacity $studio-transition-duration;
    }
  }

  .navbar-icon {
    width: 25px;
    height: 25px;
  }

  .item-gray {
    color: $byggtjanst-gray;
  }

  .item-primary {
    color: $byggtjanst-primary;
  }

  .expanded-text {
    padding-left: 8px;
    margin-bottom: 0;
    white-space: nowrap;
    opacity: 0;
  }

  &.expanded {
    .expanded-text,
    .group,
    .byggtjanst-icon .byggtjanst-logo-text {
      opacity: 1;
    }

    .icon-expand {
      transform: scaleX(-1);
    }
    .group {
      display: block;
    }
  }

  li > .divider {
    border-top: 1px solid $byggtjanst-gray-light;
  }
}

.menu-toggle {
  display: block;
  position: relative;
  top: 18px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;

  .group {
    opacity: 1;
  }
  li {
    text-decoration: none;
    color: #232323;
  }

  .burger-button {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    background: transparent;
    border: 0;

    &.burger-open > span {
      opacity: 1;
      transform: translate(0, -14px) rotate(45deg);
    }

    &.burger-open > span:nth-last-child(3) {
      transform: rotate(45deg);
      opacity: 0;
    }

    &.burger-open > span:nth-last-child(2) {
      transform: translate(0, 14px) rotate(-45deg);
    }

    &.burger-open ~ .studio-navbar {
      transform: none;
    }
  }

  // Animated hamburger icon
  .burger {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: $byggtjanst-gray-six;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform $studio-transition-duration
        cubic-bezier(0.77, 0.2, 0.05, 1),
      background $studio-transition-duration cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity $studio-transition-duration ease;

    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }

  div.studio-navbar {
    .dropdown {
      button.btn {
        &.dropdown-opener {
          min-width: 220px;
          border-radius: 8px;
          justify-content: unset;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          height: auto;
          svg {
            margin-right: 0.5rem;
          }

          .dropdown-btn-text {
            display: flex;
          }
        }
      }
    }
  }

  .studio-navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 300px;
    height: calc(100vh + 60px);
    min-height: calc(100vh + 60px);
    margin: -60px 0 0 -50px;
    padding: 100px 0px 40px 25px;
    background: white;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform $studio-transition-duration
      cubic-bezier(0.77, 0.2, 0.05, 1);

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .expanded-text {
      opacity: 1;
    }
  }
}
.reversed {
  rotate: (180deg);
}

.bottom-wrapper {
  svg {
    width: 151px;
    height: 38px;
    opacity: 0;
    transition: opacity $studio-transition-duration;
  }
  span {
    opacity: 0;
    transition: opacity $studio-transition-duration;
  }
  &.expanded {
    span {
      opacity: 1;
    }
    svg {
      opacity: 1;
    }
  }
}

.bottom-divider {
  margin-left: 15px;
  max-width: 30px;
  margin-top: 8px;
  margin-bottom: 2px;
  border-bottom: 1px solid #bebebe;

  &.expanded {
    margin-left: 9px;
    max-width: 222px;
  }
}

.nav-footer {
  margin-left: 10px;
  span {
    margin-top: 10px;
  }
}

.btn.btn-link.navbar-toggler {
  color: #757575;
  margin-left: 6px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.expanded {
    margin-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
