.project-card-empty {
    background-color: #f5f5f5;
    min-width: 25rem;
    box-shadow: none;
    border-style: dashed;
    border-color: #e7e7e7;
    border-top-width: 0.9rem;
    border-bottom-width: 0.9rem;
    border-left-width: 1rem;
    border-right-width: 1rem;

    &:hover {
      transform: none;
      filter: none;
    }

    .btn {
      min-width: 200px;
      min-height: 44px;
    }
  }