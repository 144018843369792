@import 'lib/shared-assets/styles/_variables.scss';

.card.specification-card {
  color: $byggtjanst-gray-six;
  flex: 1 1 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $byggtjanst-gray-nine;
  }
  background: none;
  max-width: 12rem;
  height: 16rem;
  margin-bottom: 16rem;
  border-radius: 0;
  box-shadow: none;
}
.specification-shape {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 2rem, 100% 100%, 0 100%);
  background: white;
  height: 100%;
}

.specification-project-name {
  font-size: 0.9rem;
}

.specification-card-spacing {
  padding: 0 0.5rem;

  &--legacy {
    min-width: Max(16.666%, 11.5rem);
    padding: 0.5rem;
  }
}

.specification-status {
  font-size: 0.75rem;
  font-style: italic;
  color: $byggtjanst-darkblue;
  font-family: $font-family-light;
}

.specifications-list {
  display: flex;
  list-style: none;
  margin: 0rem -1.5rem 1rem -0.5rem;
  padding: 0;
  flex-wrap: wrap;
  max-height: 18.5rem;
  overflow: hidden;
}

@include media-breakpoint-down(sm) {
  .specifications-list {
    margin: 0.5rem -0.5rem 1rem 0;
    flex-wrap: wrap;
  }

  .card.specification-card {
    max-width: 11.5rem;
    margin-right: 0.5rem;
  }
}

.specification-content {
  filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25));
}

.specification-item {
  border-radius: 8px 0px 8px 8px;
  filter: drop-shadow(0px 4px 4px rgba(190, 190, 190, 0.25));
  transition: all 0.15s ease-in-out;

  &:hover {
    filter: drop-shadow(0 2px 4px rgba(51, 51, 51, 0.25));
  }

  .card.specification-card {
    min-width: 178px;
    min-height: 18rem;

    .card-header {
      height: 25% !important;
    }

    .card-body {
      height: 62% !important;

      .specification-description {
        border: none;
        height: unset;
        color: $byggtjanst-gray;
      }
    }

    .card-footer {
      height: 13% !important;
    }
  }
}

.specification-icon {
  color: white;
  background: $byggtjanst-lightgreen;
  border-radius: 6px;
  height: 2rem;
  width: 2.5rem;
  font-size: 12px;

  &.blue {
    background: $byggtjanst-blue;
  }
}

.specification-description {
  border-top: 2px solid $byggtjanst-gray-one;
  height: 20%;
}
