@import 'lib/shared-assets/styles/_variables.scss';

#project-delete-modal {
  .modal-header {
    border-bottom: 0;
  }

  .del-btn {
    min-width: 200px;
    min-height: 44px;
  }

  .text-bottom-border {
    border-bottom: 1px solid $byggtjanst-gray-nine;
  }
}
