@import '../styles/variables';

.text-36 {
  font-size: 36px;
}
.text-24 {
  font-size: 24px;
}

.text-bigger {
  font-size: 20px;
}

.text-larger {
  font-size: 18px;
}

.text-large {
  font-size: 16px;
}

.text-medium {
  font-size: 14px;
}

.text-small {
  font-size: 12px;
}

.text-body {
  font-size: $body-text-font-size;
}

.text-smaller {
  font-size: 11px;
}

.text-smallest {
  font-size: 10px;
}

.text-9 {
  font-size: 9px;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

// Style for icon-button (a button containing an icon)
.btn-icon {
  $material-icon-line-height: 1;
  line-height: $material-icon-line-height; // Button line height should match material line height
}

// Limit image width to 100% so it doesn't expand outside its container.
.limit-image-width img {
  width: 100%;
  height: auto;
}

// Override any other font-family to match the rest of the site
.keep-font-family * {
  font-family: $byggtjanst-font-family !important;
}

.keep-body-font-size * {
  font-size: $body-text-font-size !important;
}

.sticky-bottom {
  @extend .sticky-top;
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.multiline-truncate-short {
  -webkit-line-clamp: 5;
}

.multiline-truncate-long {
  -webkit-line-clamp: 15;
}

.multiline-truncate-short,
.multiline-truncate-long {
  // Truncate text and show ellipsis (...) after X rows
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.mirror {
  transform: scale(-1);
}

.resize-none {
  resize: none;
}

.font-weight-700 {
  font-weight: 700;
}
.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}
.font-color-gray-8 {
  color: $byggtjanst-gray-eight;
}
.font-color-gray-4 {
  color: $byggtjanst-gray-four;
}
