@import '../../lib/shared-assets/styles/_variables.scss';

.participant-add-modal {
  &__header {
    margin-top: 5rem;
  }

  &__text {
    max-width: 402px;
  }

  &__info-group {
    margin-bottom: 1rem;

    .participant-add-modal__label {
      margin-bottom: 0;
    }
  }

  &__label {
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__button {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
  }

  &__icon {
    color: $byggtjanst-primary;
    font-size: 1.75rem !important;
  }

  &__warning {
    background: $byggtjanst-orange;
    color: #ffffff;
    border-left: 5px solid #ffc7a6;
    border-radius: 0px 8px 8px 0px;
    margin-bottom: 20px;

    &-body {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 30px 30px 30px 0px;
    }

    &-icon {
      display: flex;
      flex: 0 1 80px;
      color: #ffffff;
      width: 35px !important;
      height: 35px !important;
    }

    &-text {
      font-weight: bold;
    }
  }

  &__input {
    &.form-control {
      width: 100%;
      min-height: 44px;
      border-radius: 8px;
      border: 0.5px solid #8b8f96;
      box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
      padding: 0 1rem;
      line-height: 40px;
      background: #ffffff;

      &:focus,
      &:active {
        border-radius: 8px;
        border: 0.5px solid #8b8f96;
        box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
      }
    }

    &-group {
      &.form-group {
        margin-bottom: 2rem;
      }
    }
  }

  &__footer {
    margin-top: 3rem;
  }

  .modal-dialog,
  .modal-content {
    min-height: 612px;
    max-width: 662px;
    min-width: 662px;
  }

  .modal-content {
    background: #ffffff;
  }

  .modal-body {
    overflow-y: visible;
  }

  .btn[type='submit'] {
    min-width: 200px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border: none;
    }
  }
}

.project-role-specification {
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  &__row {
    display: flex;
    padding: 1rem 0rem;
    border-bottom: 0.5px solid #bebebe;

    &:first-child {
      padding-top: 0;
    }
  }

  &__col {
    display: flex;

    &:nth-child(odd) {
      flex: 0 1 45%;
      font-weight: bold;
      padding-left: 4rem;
    }
    &:nth-child(even) {
      flex: 0 1 55%;
      padding-right: 4rem;
    }
  }

  &__back-btn {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
  }

  .modal-dialog,
  .modal-content {
    min-width: 927px;
    min-height: 654px;
  }

  .modal-body {
    flex: 1 1 auto;
    padding: 0;
  }
}

@include media-breakpoint-down(md) {
  .participant-add-modal,
  .project-role-specification {
    .modal-dialog,
    .modal-content {
      min-width: auto;
      min-height: auto;
    }
  }

  .project-role-specification {
    &__row {
      flex-direction: column;
    }

    &__col {
      &:nth-child(odd),
      &:nth-child(even) {
        flex: auto;
        padding-left: 0;
      }
    }
  }
}

.link-container {
  background: $byggtjanst-gray-one;
  font-weight: 700;
  border-radius: 4px;
  margin-left: 10px;
  padding: 18px 33px;
  text-align: center;
  color: $byggtjanst-gray-nine;
}

.btn.btn-link.d-flex {
  padding: 0;
}
