@import '../../lib/shared-assets/styles/_variables.scss';
.participant-accordion {
  margin: 1rem 0 !important;

  &:first-child {
    margin-top: 0 !important;
  }

  &.MuiAccordion-root {
    &:before {
      display: none;
    }
  }

  &.MuiAccordion-rounded,
  &.MuiAccordion-rounded:first-child,
  &.MuiAccordion-rounded:last-child {
    border-radius: 8px;
  }

  &.MuiPaper-root {
    color: $byggtjanst-gray-nine;
  }

  &.MuiPaper-elevation1 {
    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
    transition: transform 0.5s, box-shadow 0.15s ease-in-out;
  }

  &:hover {
    box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
  }

  .MuiSvgIcon-root {
    color: #333333;
  }

  .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: auto;
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(-180deg) !important;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &-item {
    &__content {
      padding-top: 5px;
    }
    &__details {
      margin-bottom: 12px;
    }
    &__label--bold {
      margin-bottom: 12px;
    }
    &__link,
    &__button {
      padding: 0 0.75rem;
    }
  }

  &__label,
  &-item__label {
    &--bold {
      font-weight: bold;
    }
    &--muted {
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px 8px 16px 16px;
  }
}
